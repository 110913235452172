import React from "react"
import cx from "classnames"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(12.5),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(8),
    },
  },
  grid: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      justifyContent: "center",
      flexDirection: 'column-reverse'
    },
  },
  gridOdd: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column-reverse'
    }
  },
  content: {
    paddingTop: `${theme.spacing(10)}px !important`,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0 !important",
    },
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  image: {
    left: '-4vw',
    position: 'relative',
    transform: "scale(0.9)",
  },
}))

const OfferPage = ({ data = {} }) => {
  const classes = useStyles()
  const { datoCmsOfferPage = {} } = data
  const { seo = {}, sections = [] } = datoCmsOfferPage

  return (
    <Layout bgColor="#ffdd00">
      <Seo title={seo.title} description={seo.description} />
      <Box className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          {sections.map(({ id, title, description, image }, index) => {
            const odd = index % 2 !== 0
            return (
              <Grid
                key={id}
                container
                spacing={6}
                className={cx(classes.grid, odd && classes.gridOdd)}
              >
                <Grid item md={6} sm={12} className={classes.content}>
                  <Typography variant="h1" className={classes.title}>
                    {title}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <GatsbyImage
                    alt={`Zdjęcie ${title}`}
                    image={getImage(image)}
                    className={classes.image}
                  />
                </Grid>
              </Grid>
            )
          })}
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query OfferPageQuery {
    datoCmsOfferPage {
      seo {
        title
        description
      }
      sections {
        title
        id
        description
        image {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
    }
  }
`

OfferPage.defaultProps = {
  data: {},
}

export default OfferPage
